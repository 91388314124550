/* RandomRecord.css */

.random-record-container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.fetch-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.fetch-button:hover {
  background-color: #0056b3;
}

.delete-button {
  padding: 10px 20px;
  background-color: #ff002b;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.delete-button:hover {
  background-color: #b30000;
}

.record-details {
  margin-top: 20px;
}

.record-details h3 {
  margin-bottom: 10px;
}

.record-details p {
  margin: 5px 0;
}

.poster-image {
  max-width: 100%; /* Ensure the image doesn't exceed the width of its container */
  height: auto; /* Maintain the image's aspect ratio */
  display: block; /* Ensure the image is displayed as a block element */
  margin: 0 auto; /* Center the image horizontally within its container */
}
/* Styles for the Watch button */
.watch-button {
  background-color: #4CAF50; /* Green color for button background */
  color: white; /* White color for text */
  padding: 10px 20px; /* Padding around the text */
  border: none; /* Remove default button border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Show pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.watch-button:hover {
  background-color: #45a049; /* Darker green color on hover */
}
/* RandomRecord.css */

/* Styles for the IMDb-style URL button */
.imdb-url-button {
  background-color: #f5c518; /* IMDb yellow color for button background */
  color: black; /* Black color for text */
  padding: 10px 20px; /* Padding around the text */
  border: none; /* Remove default button border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Show pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.imdb-url-button:hover {
  background-color: #f0b408; /* Darker yellow color on hover */
}
.button-space {
  margin-bottom: 10px; /* Adjust the margin as needed */
}